$color-background: #e6e6e6;
$color-primary: #2d8097;
$color-secondary: #bd5a00;
$color-tertiary: #c0d6df;

$color-text: #24343a;
$color-text-button: #fff;
$color-heading-bg: #dbe9ee;
$color-table-row: lighten($color-text, 80%);

$color-toggle-on: $color-primary;
$color-toggle-off: $color-secondary;

$font-url:
  'https://fonts.googleapis.com/css?family=Raleway:500|Pontano+Sans|Fira+Mono';
$font-name: 'Pontano Sans';
$font-name-mono: 'Fira Mono';

